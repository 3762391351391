'use strict';

const WORDS:Set<string> = new Set<string>();

export function addWord(...words:string[]):void {
  words.forEach((word) => {
    WORDS.add(word.trim().toLowerCase());
  });
}

export function parseHasIn(input:string|undefined):string[] {
  if (input == undefined) {
    return ['', '', '', '', ''];
  }

  const pieces:string[] = input.split(',');
  if (pieces.length != 5) {
    throw Error('Input value for hasIn parameter must have 5 strings separated by commas.');
  }
  for (let index = 0;index < 5;index++) {
    // todo: filter to [a-z]
    pieces[index] = pieces[index].toLowerCase().trim();
  }
  return pieces;
}

export function parseAbsent(input:string|undefined):string {
  if (input == undefined) {
    return '';
  }
  return input.toLowerCase();
}

export function parseHasAt(input:string|undefined):string {
  if (input == undefined) {
    return '?????';
  }
  if (input.length != 5) {
    throw Error('Exact match string must be length 5 with ? at unknonwn places.');
  }
  return input;
}

// ['f',
// undefined,
// undefined,
// 'n',
// 'y'
// ]
export function findPossibleWords(
  knownLetters: string,
  possibleLetters: string[],
  excludedLetters: string,
  wordList?:string[]|undefined
): string[] {
  if (!wordList) {
    wordList = Array.from(WORDS.values());
  }
  const possible:string[] = wordList.filter((w) => {
    // 1. First exclude if known any must-match letter doesn't match
    for (let i = 0;i < 5;i++) {
      const knownLetter = knownLetters.charAt(i);
      const currentChar = w.charAt(i);
      if ('?' == knownLetter) {
        continue;
      } else if (knownLetter != currentChar) {
        return false;
      }
    }

    // 2. Exclude any word that contains any letter in our excluded letters list.
    for (let i = 0;i < excludedLetters.length;i++) {
      const l:string = excludedLetters.charAt(i);
      if (w.indexOf(l) > -1) {
        return false;
      }
    }

    for (let index = 0;index < 5;index++) {
      const letterList = possibleLetters[index];
      for (let i = 0;i < letterList.length;i++) {
        const currentLetter:string = letterList.charAt(i);
        if (currentLetter == w.charAt(index)) {
          return false;
        } else if (w.indexOf(currentLetter) <= -1) {
          return false;
        }
      }
    }
    return true;
  });
  return possible.sort();
}


