'use strict';
import './index.css';
import React, { useState } from 'react';
import { addWord, findPossibleWords } from './solver';
import { createRoot } from 'react-dom/client';

import wordList from './words.json';

const isLetter = (str:string):boolean => {
  const chr:string = str.toLocaleLowerCase().charAt(0);
  if (str.length == 1 && chr >= 'a' && chr <= 'z') {
    return true;
  }
  return false;
};

const letterTyped = (
  event:React.KeyboardEvent<HTMLInputElement>,
  prevCell:string|undefined,
  nextCell:string|undefined
) => {
  if (event.key === 'Backspace' || event.key === 'Clear' || event.key === 'Delete') {
    if (prevCell) {
      const focusElement = document.getElementById(prevCell);
      focusElement.focus();
    }
    event.currentTarget.value = '';
  } else if (isLetter(event.key)) {
    if (nextCell) {
      const focusElement = document.getElementById(nextCell);
      focusElement.focus();
    }
    event.currentTarget.value = event.key;
  } else {
    event.currentTarget.value = '';
  }
  event.preventDefault();
  updateLetterColours();
};

const updateLetterColours = () => {
  [1, 2, 3, 4, 5].forEach((num) => {
    const cell:HTMLElement = document.getElementById('c' + num);
    cell.classList.remove(
      'absentLetter',
      'correctLetter',
      'somewhereLetter',
      'possibleLetter'
    );

    const targetLetter = cell.value!;
    if (targetLetter != '') {
      if (ABSENT_CHARS.includes(targetLetter)) {
        cell.classList.add('absentLetter');
      } else if (PRESENT_CHARS.charAt(num-1) == targetLetter) {
        cell.classList.add('correctLetter');
      } else {
        SOMEWHERE_CHARS.forEach((s, index) => {
          if (s.includes(targetLetter)) {
            if (num-1 == index) {
              cell.classList.add('somewhereLetter');
            } else {
              cell.classList.add('possibleLetter');
            }
          }
        });
      }
    }
  });
};

let ABSENT_CHARS = '';

const SOMEWHERE_CHARS = [
  '', '', '', '', ''
];

let PRESENT_CHARS = '?????';

const setSomewhere = (position:number):void => {
  const cellId:string = 'c' + position;
  const focusElement:HTMLElement = document.getElementById(cellId);
  const letter:string = focusElement.value!;
  if (isLetter(letter)) {
    if (SOMEWHERE_CHARS[position-1].indexOf(letter) == -1) {
      SOMEWHERE_CHARS[position-1] = SOMEWHERE_CHARS[position-1] + letter;
    }
  }

  updateWordList();
};

let updateWordsListData;

const AbsentLetters = () => <div>Letters absent from word: {
  ABSENT_CHARS.length > 0 ? ABSENT_CHARS : 'None known'
}</div>;

const SomewhereLetters = () => {
  const hasChars:boolean = SOMEWHERE_CHARS.some((c) => {
    return c.length > 0;
  });
  if (hasChars) {
    return (<><div>With these letters somewhere in the word, but not at those positions: {
      SOMEWHERE_CHARS.map((l, psn) => {
        return <div key={`word-key-${psn}`} className="characterSet">[{l}]</div>;
      })
    }</div></>);
  } else {
    return <div>No characters are known to occur within the word outside specified positions.</div>;
  }
};

const PresentLetters = () => <div>Contains letters at known positions: {
  PRESENT_CHARS === '?????' ? 'None known' : PRESENT_CHARS
}</div>;

function WordList() {
  const [words, setWords] = useState<string[]>([]);
  updateWordsListData = setWords;

  wordList.forEach((w) => {
    addWord(w);
  });

  return (<>
    <AbsentLetters /><PresentLetters /><SomewhereLetters />
    { words.length == 0 ?
      <div>No words yet removed from list</div> :
      <div>{ words.length } possible words remain: {
        words.map((word) => <span className="word" key={word}>{word}</span>) }
      </div>}
  </>);
}

const setAbsent = (position:number):void => {
  const focusElement:HTMLElement = document.getElementById('c' + position);
  const letter:string = focusElement.value!;
  ABSENT_CHARS += letter;
  updateWordList();
};

const setPresent = (position:number):void => {
  const focusElement:HTMLElement = document.getElementById('c' + position);
  const letter = focusElement.value!;
  PRESENT_CHARS = PRESENT_CHARS.substring(0, position-1) + letter + PRESENT_CHARS.substring(position, 5);
  updateWordList();
};

const updateWordList = ():void => {
  const possibleWords:string[] = findPossibleWords(PRESENT_CHARS, SOMEWHERE_CHARS, ABSENT_CHARS, wordList);
  updateWordsListData(possibleWords);
  updateLetterColours();
};

const container = document.getElementById('root');
const root = createRoot(container!);
const TO_FIVE:number[] = [1, 2, 3, 4, 5];

const Board = () => {
  return (<>
    <div id="board">
      <div className="row" key="inputRow">
        {
          TO_FIVE.map((num) => {
            const nextCell:string = num < 5 ? 'c' + (num+1) : undefined;
            const prevCell:string = num > 1 ? 'c' + (num-1) : undefined;
            const cellId:string = 'c' + num;
            return <input className="tile" type="text" key={cellId} id={cellId} maxLength={1}
              onKeyUp={(event) => letterTyped(event, prevCell, nextCell)} />;
          })}
      </div>
      <div className="row" key="absentRow">
        {
          TO_FIVE.map((num) => {
            const cellId:string = 'a' + num;
            return <button className="tile absent" key={cellId} id={cellId} onClick={
              () => setAbsent(num)}>&#x2718;</button>;
          })}
      </div>
      <div className="row" key="somewhereRow">
        {
          TO_FIVE.map((num) => {
            const cellId:string = 's' + num;
            return <button className="tile absent" key={cellId} id={cellId} onClick={
              () => setSomewhere(num)}>?</button>;
          })}
      </div>
      <div className="row" key="presentRow">
        {
          TO_FIVE.map((num) => {
            const cellId:string = 'p' + num;
            // We only want to disable the 'present' button because if the user next uses a
            // completely different word that doesn't have this letter in it, that one would
            // be allowed to have that letter elsewhere in the word - or that letter not be
            // present.
            return <button className="tile absent" key={cellId} id={cellId} onClick={
              () => {
                setPresent(num);
              }}>&#x2705;</button>;
          })}
      </div>
      <div>To use this tool:<br />
        &#x2718; a letter to indicate it does not occur in the word.<br />
        ? - the letter is in the word, but not at that position.<br />
        &#x2705; a letter to indicate it must occur at that position.<br />
      </div>
    </div>
  </>);
};

root.render(
  <>
    <div className="title">Solvle</div>
    <div id="game">
      <div id="board-container">
        <Board />
      </div>
    </div>
    <WordList />
    <div className="solvleInfo">Solvle is a <a
      href="https://www.nytimes.com/games/wordle/index.html">Worlde</a> Solver by Tim Rowe.  You can find
      the source code for this tool at <a
      href="https://bitbucket.org/tjsrowe/wordle-solver/">https://bitbucket.org/tjsrowe/wordle-solver/</a>.
      This tool is intended for a bit of fun only - please don't post solutions to daily Wordle problems
      and don't use it to spoil the fun of others.</div>
  </>
);

